import { Component, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { map, takeUntil } from 'rxjs/operators'
import { ShoutlyNotification, ShoutlyNotificationUI, NotificationsService, LocaleService, NavigationTitleService } from 'shared-lib'
import { Subject, of } from 'rxjs'
import { marker as _ } from '@colsen1991/ngx-translate-extract-marker'

const MOCKED_NOTIFICATIONS: ShoutlyNotification[] = [
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: '2023-09-13T08:31:20.000000Z',
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  },
  {
    id: 'bab7ea04-9ad1-45b6-a1cf-9d9e1904eedf',
    type: 'App\\Notifications\\DeclineCancellationNotification',
    data: {
      slug: 'collab_cancel_declined',
      collab_id: 89
    },
    notifiable_type: 'App\\Shoutly\\User',
    notifiable_id: 2,
    action: null,
    read_at: null,
    created_at: '2023-09-13T08:31:20.000000Z',
    updated_at: '2023-09-13T08:31:20.000000Z',
    value: {
      slug: 'collab_cancel_declined',
      title: 'collaboration',
      description: 'Collaboration cancellation has been rejected',
      action: 'collaboration'
    }
  }
]

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  constructor (
    private router: Router,
    private notificationsService: NotificationsService,
    private localeService: LocaleService,
    private NavigationTitleService: NavigationTitleService
  ) {
    this.NavigationTitleService.setTitle(_('notifications'), true)
  }

  ngOnInit (): void {
    this.getNotifications()

    this.localeService.locale$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(locale => {
        this.locale = locale
      })
  }

  ngOnDestroy (): void {
    this.destroy$.next()
  }

  public locale: string
  public notifications: ShoutlyNotificationUI = { notifications: [], has_unread: false, unread_notifications: 0 }
  public isLoading = false
  private destroy$: Subject<void> = new Subject()

  public svgAttrs = {
    width: '18',
    height: '18',
    stroke: 'none',
    fill: 'none'
  }

  public reviewNotification (notification: ShoutlyNotification) {
    const url = notification.value.action === 'collaboration'
      ? this.getCollabUrl(notification.data.collab_id)
      : this.getDepartmentUrl(notification.data.org_id)

    if (!notification.read_at) this.markNotificationAsRead(notification.id)

    return this.redirectTo(url)
  }

  redirectTo (path: string) {
    return this.router.navigate([path])
  }

  getCollabUrl (id = null): string {
    const res = id ? '/collaborations/details/' + id : '/collaborations'
    return res
  }

  getDepartmentUrl (id = null) {
    const res = id ? '/departments/details/' + id : '/departments'
    return res
  }

  private getNotifications () {
    this.isLoading = true

    return this.notificationsService.getNotifications()
    // return this.getMockedNotif()
      .pipe(
        map(data => {
          const notifications = data
          const unread_notifications: number = data.map(data => data.read_at ? 0 : 1).reduce((a, b) => a + b, 0)
          const has_unread = data.map(data => data.read_at ? 1 : 0).some(data => data === 0)
          return { notifications, has_unread, unread_notifications }
        }))
      .subscribe({
        next: data => {
          this.notifications = data
          // console.log('component notif', data)
        },
        complete: () => { this.isLoading = false }
      })
  }

  markNotificationAsRead (id) {
    this.notificationsService.getNotification(id)
      .subscribe(
        data => { this.notifications.notifications = this.updateNotifications(data, this.notifications.notifications) }
      )
  }

  updateNotifications (updatedNotification: ShoutlyNotification, notifications: ShoutlyNotification[]): ShoutlyNotification[] {
    const updatable_id = updatedNotification.id
    return notifications.map(x => {
      if (x.id === updatable_id) x = updatedNotification
      return x
    })
  }

  private getMockedNotif () {
    return of(MOCKED_NOTIFICATIONS)
  }
}
