import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NotificationsComponent } from './components/notifications/notifications.component'
import { NotificationRoutingModule } from './notifications-routing.module'
import { TranslateModule } from '@ngx-translate/core'
import { SharedLibModule } from 'shared-lib'

@NgModule({
  declarations: [
    NotificationsComponent
  ],
  imports: [
    NotificationRoutingModule,
    CommonModule,
    SharedLibModule,
    TranslateModule.forChild()
  ],
  exports: [
    NotificationsComponent
  ]
})
export class NotificationsModule { }
