<div class="wrapper-2">
    <mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
    
    <div class="notifications-wrapper" *ngIf="!isLoading">
        <div *ngIf="notifications?.notifications?.length">
            <div *ngFor="let notif of notifications?.notifications" class="notification-item" [ngClass]="notif?.read_at ? 'read' : 'unread'">                
                <!-- <span class="is-unread" *ngIf="!notif.read_at">a</span> -->
                <div *ngIf="notif.created_at" class="notification-time secondary-text">{{ notif.created_at | date:'shortTime' : undefined : locale | titlecase}}</div>
                <p *ngIf="notif.created_at" class="notification-date secondary-text">{{ notif.created_at | date:'shortDate' : undefined : locale | titlecase}}</p>    
                <b>{{ notif.value.title | translate | titlecase }}</b>
                <p>{{ notif.value.description }}</p>
                <div>
                    <button mat-flat-button color="primary" (click)="reviewNotification(notif)">{{ 'review' | uppercase | translate }}</button>
                </div>
            </div>
        </div>
        <div class="notification-item notification-empty" *ngIf="!notifications?.notifications?.length">
            <span translate>No notifications available</span>
        </div>
    </div>
</div>